import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Wrapper from "../components/layout/wrapper"
import Style from './guide.module.scss'



const GuidePage = ({ data: { prismicGuide: { data } } }) => {

    return (
        <Layout>
          <SEO title="A simple guide for finding the right accommodation for students." />
          <section className="page-hero">
                <Wrapper>
                  <h1>Accommodation Guide</h1>
                  <p style={{marginBottom: "0px"}}>Learn which type of accommodation suits your requirements.</p>
                </Wrapper>
          </section>
          <div>
              {data.types.map(x => (
                  
                      <div className={Style.columns}>
                        <div className={Style.textColumn}>
                        <Wrapper>
                            < div dangerouslySetInnerHTML={{ __html: x.type_title.html}} />
                            < div dangerouslySetInnerHTML={{ __html: x.title_body.html}} />
                        </Wrapper>
                        </div>
                        <div className={Style.imageColumn}>
                            <Img className={Style.photo} fluid={x.type_image.localFile.childImageSharp.fluid} />
                        </div>
                      </div>
                 
              ))}
          </div>

          <section className={Style.guideCta}>
              <h2>Ready to start your journey?</h2>
              <br />
              <Link className={Style.searchButton} to="/locations">Find a home</Link>
          </section>
        </Layout>
      )
}

export default GuidePage

export const query = graphql`
query GuideQuery {
    prismicGuide {
      data {
        types {
          type_title {
            html
          }
          title_body {
            html
          }
          type_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`